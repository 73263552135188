import React, {Component} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Homepage from './Pages/Homepage1';
import Homepage2 from './Pages/Homepage2';

import Jobprofile from './Pages/Jobprofile';
import Jobmyresume from './Pages/Jobmyresume';
import AppliedJobs from './Pages/User/AppliedJobs';
import Jobsalert from './Pages/Jobsalert';
import Jobsavedjobs from './Pages/Jobsavedjobs';
import Jobcvmanager from './Pages/Jobcvmanager';
import Changepasswordpage from './Pages/Changepasswordpage';

import Companyprofile from './Pages/Companyprofile';
import Companyresume from './Pages/Companyresume';
import Componypostjobs from './Pages/Componypostjobs';
import Companymanage from './Pages/Companymanage';
import Companytransactions from './Pages/Companytransactions';
import Browsecandidates from './Pages/Browsecandidates';


import Aboutus from './Pages/Aboutus';
import Jobdetail from './Pages/Jobdetail';
import Companies from './Pages/Companies';
import Freejobalerts from './Pages/Freejobalerts';
import Browsejoblist from './Pages/Browsejoblist';
import Browsejobgrid from './Pages/Browsejobgrid';
import Browsejobfilterlist from './Pages/Browsejobfilterlist';
import Browsejobfiltergrid from './Pages/Browsejobfiltergrid';

import Categoryalljob from './Pages/Categoryalljob';
import Categorycompanyjob from './Pages/Categorycompanyjob';
import Categorydesignationsjob from './Pages/Categorydesignationsjob';
import Categoryjobs from './Pages/Categoryjobs';
import Categorylocationjobs from './Pages/Categorylocationjobs';
import Categoryskilljobs from './Pages/Categoryskilljobs';

import Portfoliogrid2 from './Pages/Portfoliogrid2';

import LoginPage from './Pages/LoginPage';
import Loginpage2 from './Pages/Loginpage2';
import Loginpage3 from './Pages/Loginpage3';

import Register1 from './Pages/Register1';
import Register2 from './Pages/Register2';
import Error404 from './Pages/Error404';

import Contact from './Pages/Contact';


import Blogclassic from './Pages/Blogclassic';
import Blogclassicsidebar from './Pages/Blogclassicsidebar';
import Blogdetailgrid from './Pages/Blogdetailgrid';
import Blogdetailgridsidebar from './Pages/Blogdetailgridsidebar';
import Blogleftimg from './Pages/Blogleftimg';
import Blogdetail from './Pages/Blogdetail';
import ScrollToTop from './Element/ScrollToTop';

import ScrappingGuard from './Pages/Admin/ScrappingGuard';
import Unauthorized from './Pages/Unauthorized';
import TermsOfService_01_10_2024 from './Pages/TermsOfService_01_10_2024';
import PrivacyPolicy_01_10_2024 from './Pages/PrivacyPolicy_01_10_2024';

import { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { ProtectedRoute } from './Element/ProtectedRoute';
import MaintenanceMode from './Pages/MaintenanceMode';
import { ProtectionRoles } from './Element/ProtectedBase';
import * as reactRouterDom from "react-router-dom";
import UserSettings from './Pages/User/Settings';

class Markup extends Component{
	render(){
		return(
			<SuperTokensWrapper>
				<BrowserRouter>
					<div className="page-wraper">
						<Routes>
							{getSuperTokensRoutesForReactRouterDom(reactRouterDom, [ThirdPartyPreBuiltUI, PasswordlessPreBuiltUI])}
							<Route path='/' exact element={<Homepage2 />} />
							<Route path='/job/:jobId/:searchQueryTags?' exact element={<Jobdetail />} />
							<Route path='/user/appliedJobs' exact element={
									<SessionAuth key={"loggedInUsersOnlyAppliedJobs"}>
										<ProtectedRoute roles={[ProtectionRoles.User]} rolesLogicalOperator='and' redirectPath='/unauthorized' component={<AppliedJobs />} />
									</SessionAuth>	
								} />
							<Route path='/user/settings' exact element={
									<SessionAuth key={"loggedInUsersOnlySettings"}>
										<ProtectedRoute roles={[ProtectionRoles.User]} rolesLogicalOperator='and' redirectPath='/unauthorized' component={<UserSettings />} />
									</SessionAuth>
								} />
							<Route path='/admin/scrappingGuard' exact element={
									<SessionAuth onFailureRedirection={() => "/"} key={"loggedInAdminsOnlyScrappingGuard"}>
										<ProtectedRoute roles={[ProtectionRoles.Admin]} rolesLogicalOperator='and' redirectPath='/unauthorized' component={<ScrappingGuard />} />
									</SessionAuth>
								} />

							<Route path='/unauthorized' exact element={<Unauthorized />} />
							<Route path='/maintenance' exact element={<MaintenanceMode />} />
							<Route path='/auth' exact element={<LoginPage />} />
							<Route path='/termsOfService' exact element={<TermsOfService_01_10_2024 />} />
							<Route path='/privacyPolicy' exact element={<PrivacyPolicy_01_10_2024 />} />
							{/* <Route path='/index-2' exact element={<Homepage2 />} /> */}
							
							{/* <Route path='/jobs-profile' exact element={<Jobprofile />} />
							<Route path='/jobs-my-resume' exact element={<Jobmyresume />} />
							<Route path='/jobs-applied-job' exact element={<Jobsappliedjob />} />
							<Route path='/jobs-alerts' exact element={<Jobsalert />} />
							<Route path='/jobs-saved-jobs' exact element={<Jobsavedjobs />} />
							<Route path='/jobs-cv-manager' exact element={<Jobcvmanager />} />
							<Route path='/jobs-change-password' exact element={<Changepasswordpage />} />
								
								
							<Route path='/company-profile' exact element={<Companyprofile />} />
							<Route path='/company-resume' exact element={<Companyresume />} />
							<Route path='/company-post-jobs' exact element={<Componypostjobs />} />
							<Route path='/company-manage-job' exact element={<Companymanage />} />
							<Route path='/company-transactions' exact element={<Companytransactions />} />
							<Route path='/browse-candidates' exact element={<Browsecandidates />} /> */}
							
							
							{/* <Route path='/about-us' exact element={<Aboutus />} /> */}
							{/* <Route path='/companies' exact element={<Companies />} /> */}
							{/* <Route path='/free-job-alerts' exact element={<Freejobalerts />} /> */}
							{/* <Route path='/browse-job-list' exact element={<Browsejoblist />} />
							<Route path='/browse-job-grid' exact element={<Browsejobgrid />} />
							<Route path='/browse-job-filter-list' exact element={<Browsejobfilterlist />} />
							<Route path='/browse-job-filter-grid' exact element={<Browsejobfiltergrid />} /> */}
							
							{/* <Route path='/category-all-jobs' exact element={<Categoryalljob />} />
							<Route path='/category-company-jobs' exact element={<Categorycompanyjob />} /> */}
							{/* <Route path='/category-designations-jobs' exact element={<Categorydesignationsjob />} /> */}
							{/* <Route path='/category-jobs' exact element={<Categoryjobs />} /> */}
							{/* <Route path='/category-location-jobs' exact element={<Categorylocationjobs />} /> */}
							{/* <Route path='/category-skill-jobs' exact element={<Categoryskilljobs />} /> */}
							
							{/* <Route path='/portfolio-grid-2' exact element={<Portfoliogrid2 />} /> */}
							
							{/*<Route path='/login-2' exact element={<Loginpage2 />} />
							<Route path='/login-3' exact element={<Loginpage3 />} />
							
							<Route path='/register' exact element={<Register1 />} />
							<Route path='/register-2' exact element={<Register2 />} />
							<Route path='/error-404' exact element={<Error404 />} /> */}
							
							{/* <Route path='/contact' exact element={<Contact />} /> */}
							
							{/* <Route path='/blog-classic' exact element={<Blogclassic />} />
							<Route path='/blog-classic-sidebar' exact element={<Blogclassicsidebar />} />
							<Route path='/blog-detailed-grid' exact element={<Blogdetailgrid />} />
							<Route path='/blog-detailed-grid-sidebar' exact element={<Blogdetailgridsidebar />} />
							<Route path='/blog-left-img' exact element={<Blogleftimg />} />
							<Route path='/blog-details' exact element={<Blogdetail />} /> */}
							
						</Routes>
					</div>
					<ScrollToTop />
				</BrowserRouter>	
			</SuperTokensWrapper>
		)
	}
	
}

export default Markup;