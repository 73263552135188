import { Guid } from "typescript-guid";

export interface IRetrieveAppliedJobs {
    retrieve(): Promise<IAmAppliedJob[]>;
    retrieveByIds(ids: Array<Guid>): Promise<IAmAppliedJob[]>;
}

export interface IRemoveAppliedJob {
    remove(id: Guid): Promise<void>;
}

export interface ISaveAppliedJob {
    save(applicationId: Guid, jobOfferId: number): Promise<void>;
}

export interface IAmAppliedJob {
    id: Guid;
    jobOfferId: number;
    positionName: string;
    companyName: string;
    companyLogoUrl: string;
    snapshotId: number;
    snapshotUrl: string;
    dateApplied: Date;
}

export interface IAmAppliedResumeFile {
    applicationId: Guid;
    fileName: string;
    dateAdded: Date;
}

export interface IRetrieveAppliedResumeFiles {
    retrieve(): Promise<IAmAppliedResumeFile[]>;
    downloadResume(applicationId: Guid): Promise<File>;
}

export interface IRemoveAppliedResumeFile {
    remove(applicationId: Guid): Promise<void>;
}

export interface ISaveAppliedResumeFile {
    save(applicationId: Guid, file: File): Promise<void>;
    saveFromOtherResume(applicationId: Guid, srcApplicationId: Guid): Promise<void>;
}

export interface INotificationsSettings {
    mailMeAboutNews: boolean;
}

export interface IUserSettings {
    notifications: INotificationsSettings;
}

export interface ISaveUserSettings {
    save(settings: IUserSettings): Promise<void>;
}

export interface IRetrieveUserSettings {
    retrieve(): Promise<IUserSettings>;
}

let Types = {
    AppliedJobsRetriever: Symbol("AppliedJobsRetriever"),
    AppliedJobsRemover: Symbol("AppliedJobsRemover"),
    AppliedJobSaver: Symbol("AppliedJobSaver"),
    AppliedResumeFilesRetriever: Symbol("AppliedResumeFilesRetriever"),
    AppliedResumeFileSaver: Symbol("AppliedResumeFileSaver"),
    AppliedResumeFileRemover: Symbol("AppliedResumeFileRemover"),
    UserSettingsRetriever: Symbol("UserSettingsRetriever"),
    UserSettingsSaver: Symbol("UserSettingsSaver")
}

export { Types };

