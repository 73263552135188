import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { AuthenticationButtons } from '../Element/AuthenticationButtons';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { signOut } from "supertokens-auth-react/recipe/session";
import Session from 'supertokens-auth-react/recipe/session';
import { HeaderOverlay } from '../Element/HeaderOverlay';
import { checkIfUserIsInRole, getUserRolesFromToken, ProtectionRoles } from '../Element/ProtectedBase';

class Header extends Component{
	private readonly naviconRef: React.RefObject<any>;

	state = {
		userName: "",
		menuShow: false,
		userRoles: [] as string[]
	}

	constructor(props: any){
		super(props);
		this.naviconRef = React.createRef();

		this.menuLinkClick = this.menuLinkClick.bind(this);
		this.generateRoleLinks = this.generateRoleLinks.bind(this);
	}

	async componentDidMount(): Promise<void> {
		if(await Session.doesSessionExist())
		{
			const payload = await Session.getAccessTokenPayloadSecurely();
			const userRoles = await getUserRolesFromToken();
			this.setState({userName: payload.email, userRoles: userRoles || []});
		}

		const navicon = document.querySelector('.navicon');
		navicon!.addEventListener('click', () => { this.setState({menuShow: !this.state.menuShow}); });
	}

	menuLinkClick(event: any) {
		event.preventDefault();
		event.target.parentElement.parentElement.querySelectorAll( "li" ).forEach( (el: any) =>
			(event.target.parentElement !== el) ? el.classList.remove('open') : ''
		);
		setTimeout(() => {
			event.target.parentElement.classList.toggle('open');
		}, 100);			
	}

	generateRoleLinks(isInRole: boolean, roleName: string, hasMultipleRoles: boolean, isDisplayedOnMobile: boolean, roleLinks: JSX.Element[]): JSX.Element[] {
		if (!isInRole) {
			return [];
		}

		if (!hasMultipleRoles) {
			return roleLinks;
		}
		const faAngleClass = isDisplayedOnMobile ? "fa fa-angle-down" : "fa fa-angle-right";
		return [
			<li key={roleName + "_menu"}>
				<Link to={'#'} className="dez-page" onClick={(e) => this.menuLinkClick(e)}>{roleName} <i className={faAngleClass}></i></Link>
				<ul className='sub-menu'>
					{roleLinks}
				</ul>
			</li>
		];
	}


	render(){
		const {userName} = this.state;
		const {menuShow: menuShowOnMobile} = this.state;
		const {userRoles} = this.state;

		const isCandidate = checkIfUserIsInRole(ProtectionRoles.User, userRoles);
		const isAdmin = checkIfUserIsInRole(ProtectionRoles.Admin, userRoles);
		const hasMultipleRoles = userRoles.length > 1;

		const candidateLinks = [
				<li key="user_appliedJobs">
					<Link to={"/user/appliedJobs"} className="dez-page">Historia CV</Link>
				</li>,
				<li key="user_settings">
					<Link to={"/user/settings"} className="dez-page">Ustawienia</Link>
				</li>
			];

		const adminLinks = [
				<li key="admin_scrappingGuard">
					<Link to={"/admin/scrappingGuard"} className="dez-page">Scrapping Guard</Link>
				</li>
			];

		const commonLinks = [
			<li key="common_logout">
				<Link to={'#'} onClick={onLogout}>Wyloguj</Link>
			</li>
		];
		
		const allLinks = [
			...this.generateRoleLinks(isAdmin, "Administrator", hasMultipleRoles, menuShowOnMobile, adminLinks), //todo: fudged! translation!
			...this.generateRoleLinks(isCandidate, "Kandydat", hasMultipleRoles, menuShowOnMobile, candidateLinks), //todo: fudged! translation!
			...commonLinks
		];

		async function onLogout() {
			await signOut();
			window.location.reload();
		}

		return(
			<>
			<header className="site-header mo-left header fullwidth">
				<HeaderOverlay showOverlay={menuShowOnMobile} onClose={() => this.setState({menuShow: false})} ></HeaderOverlay>
				<div className="sticky-header main-bar-wraper navbar-expand-lg">
					<div className="main-bar clearfix">
						<div className="container clearfix">
							
							<div className="logo-header mostion">
								<Link to="/"><img src={require('./../../images/logo.png')} className="logo" alt="img" /></Link>
							</div>
							
							<button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" ref={this.naviconRef}>
								<span></span>
								<span></span>
								<span></span>
							</button>

							<div className={"header-nav navbar-collapse menuNavbar collapse justify-content-end " + (menuShowOnMobile ? "show" : "")} id="navbarNavDropdown">
								<div className="logo-header mostion d-md-block d-lg-none">
									<Link to={'/'} className="dez-page"><img src={require('./../../images/logo.png')} alt="" /></Link>
								</div>
								<ul className="nav navbar-nav">
									<SessionAuth doRedirection={false} requireAuth={false} key="LoginLogout">
										<SessionAuth requireAuth={true} key="Logout" doRedirection={false} >
											{
												!menuShowOnMobile &&
												<li key="desktop_allMenuLinks">
													<Link to={'#'} >{userName}<i className="fa fa-chevron-down"></i></Link>
													<ul className="sub-menu">
														{allLinks} 
													</ul>
												</li>
											}
											{

												menuShowOnMobile &&	
												<>
													{allLinks}
												</>
											}
										</SessionAuth>
									</SessionAuth>
									<SessionAuth requireAuth={false} key="Login" doRedirection={false} >
										<li><AuthenticationButtons /></li>
									</SessionAuth> 
								</ul>
							</div>
						</div>
					</div>
				</div>
				
			</header>
			</>
		)
	}
}

export default Header;

