import DOMPurify from "dompurify";

export function highlightText(text, searchWords) {
    if (!text || searchWords.length === 0) return text;
  
    // Sanitize both the text and search words
    let sanitizedText = DOMPurify.sanitize(text);
    const sanitizedSearchWords = searchWords.map(word => DOMPurify.sanitize(word));
  
    // Escape any special regex characters in the sanitized search words
    const searchWordsEscaped = sanitizedSearchWords.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
  
    const regex = new RegExp(`(${searchWordsEscaped.join('|')})`, 'gi');
  
    // Replace matched words with <mark> tags
    return sanitizedText.replace(regex, (match) => `<mark style='padding: 0px !important; background-color: orange'>${match}</mark>`);
  }
  