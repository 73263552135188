import { inject, injectable } from "inversify";
import { ISaveAppliedJob } from "./abstraction";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';
import { JobApplicationsHistoryService, OpenAPI, SaveJobApplication } from "@all-it/api.users.client";
import { Guid } from "typescript-guid";

@injectable()
export class AppliedJobSaver implements ISaveAppliedJob {
    @inject(config.Types.AppliedJobsRetrieverSettings)
    private readonly _appliedJobsApiSettings!: config.IKeepAppliedJobsApiData;

    async save(applicationId: Guid, jobOfferId: number): Promise<void> {
        const jwt = await Session.getAccessToken();

        OpenAPI.BASE = this._appliedJobsApiSettings.appliedJobsApiUrl;
        OpenAPI.TOKEN = jwt;

        const body: SaveJobApplication = {
            jobOfferId: jobOfferId,
            applicationId: applicationId.toString()
        }

        await JobApplicationsHistoryService.jobApplicationsHistoryPost(body);
    }
}