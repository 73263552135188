import React, {Component} from 'react';
import { IAmJobFound } from '../../services/JobSearch';
import { SearchJobListItem } from './SearchJobListItem';
import { batch } from '../../extensions/arrayExtensions';
import Select from 'react-select';
import PaginationExtended from './Pagination';
import Session from 'supertokens-auth-react/recipe/session';
import { checkIfUserInAnyRole, getUserRolesFromToken, ProtectionRoles } from '../Element/ProtectedBase';

interface IKeepTheState {
	currentModel: IAmJobFound[];
	allModelData: IAmJobFound[] | null;
	allOrderByFields: { label: string; value: string; }[];
	currentOrderBy: { label: string; value: string };
	currentCurrency: string;
	scrollToJobs: boolean;
}

enum SortByField {
	DateAddedFromTheNewest = "dateAddedFromTheNewest",
	DateAddedFromTheOldest = "dateAddedFromTheOldest",
	SalaryFromTheHighest = "salaryFromTheHighest",
	SalaryFromTheLowest = "salaryFromTheLowest",
	Random = "random"
}

export interface JobSectionOptions {
	CurrentModel: IAmJobFound[] | null,
	MaxJobsPerPage: number,
	CurrentCurrency: string
}

class Jobsection extends Component<JobSectionOptions, IKeepTheState>{
	private readonly jobsPageChangeScrollTo: React.RefObject<any>;
	
	public constructor(props: JobSectionOptions) {
		super(props);

		const allModelData = props.CurrentModel == null ? null : batch(props.CurrentModel, props.MaxJobsPerPage);
		const firstSelectedOrderByField = {label: "od najnowszego", value: SortByField.DateAddedFromTheNewest};

		this.state = {
			allModelData: props.CurrentModel,
			currentModel: allModelData?.[0] || [],
			allOrderByFields: [
				firstSelectedOrderByField,
				{label: "od najstarszego", value: SortByField.DateAddedFromTheOldest},
				{label: "od najlepiej płatnego", value: SortByField.SalaryFromTheHighest},
				{label: "od najgorzej płatnego", value: SortByField.SalaryFromTheLowest},
			],
			currentOrderBy: firstSelectedOrderByField,
			currentCurrency: props.CurrentCurrency,
			scrollToJobs: false
		};

		this.onChangePage = this.onChangePage.bind(this);
		this.scrollToJobs = this.scrollToJobs.bind(this);
		this.onOrderByFieldChange = this.onOrderByFieldChange.bind(this);

		this.jobsPageChangeScrollTo = React.createRef();
	}

	async componentDidMount(): Promise<void> {
		if(await Session.doesSessionExist()) {
			const userRoles = await getUserRolesFromToken();

			if (checkIfUserInAnyRole([ProtectionRoles.Admin, ProtectionRoles.Tester], userRoles)) {
				const randomOrderByField = {label: "losowo", value: SortByField.Random}; //todo: fudged! translation!
				this.setState({allOrderByFields: [...this.state.allOrderByFields, randomOrderByField]});
			}
		}		
	}

	componentDidUpdate(prevProps: JobSectionOptions) {
		if (prevProps.CurrentModel !== this.props.CurrentModel) {
			const allModelData =  this.props.CurrentModel == null ? null : this.sortJobs(this.props.CurrentModel, SortByField.DateAddedFromTheNewest);
			this.setState({allModelData: allModelData, currentCurrency: this.props.CurrentCurrency, scrollToJobs: true });

			this.onOrderByFieldChange(this.state.currentOrderBy);
		}
	}

	onChangePage(pageOfItems: IAmJobFound[]) {
        this.setState({ currentModel: pageOfItems });
		if (this.state.scrollToJobs) {
			setTimeout(() => this.scrollToJobs(), 100); //todo: fudged! but without it, it doesn't work when jobsection is displayed for the first time (after 'search' box is clicked and data retrieved)
		}
    }

	onOrderByFieldChange(orderByField: {label: string, value: string} | null) {

		const allModelData = this.props.CurrentModel == null ? null : this.sortJobs(this.props.CurrentModel, orderByField!.value);
		const allModelDataBatched = allModelData == null ? null : batch(allModelData, this.props.MaxJobsPerPage);
		const currentModel = allModelDataBatched?.[0] || [];

		this.setState({allModelData: allModelData, currentOrderBy: orderByField!});

		this.onChangePage(currentModel);
	}

	sortJobs(incomingJobs: IAmJobFound[], sortBy: string): IAmJobFound[] {
		return [...incomingJobs].sort((a, b) => {
			switch(sortBy) {
				case SortByField.DateAddedFromTheNewest:
					return b.dateAdded.getTime() - a.dateAdded.getTime();
				case SortByField.DateAddedFromTheOldest:
					return a.dateAdded.getTime() - b.dateAdded.getTime();
				case SortByField.SalaryFromTheHighest:
					{
						const maxMonthlyWageA = a.wages
							.sort((x, y) => x.wageRateMonthly.to - y.wageRateMonthly.to)[0] || {wageRateMonthly: {to: 0}};
						const maxMonthlyWageB = b.wages
							.sort((x, y) => x.wageRateMonthly.to - y.wageRateMonthly.to)[0] || {wageRateMonthly: {to: 0}};

						return -1 * (maxMonthlyWageA.wageRateMonthly.to - maxMonthlyWageB.wageRateMonthly.to);
					}
				case SortByField.SalaryFromTheLowest:
					{
						const minMonthlyWageA = a.wages
							.sort((x, y) => x.wageRateMonthly.from - y.wageRateMonthly.from)[0] || {wageRateMonthly: {from: 0}};
						const minMonthlyWageB = b.wages
							.sort((x, y) => x.wageRateMonthly.from - y.wageRateMonthly.from)[0] || {wageRateMonthly: {from: 0}};

						return (minMonthlyWageA.wageRateMonthly.from - minMonthlyWageB.wageRateMonthly.from);
					}
				case SortByField.Random:
					return Math.random() - 0.5;
				default:
					return 0;
			}
		})
	}

	scrollToJobs() {
		this.jobsPageChangeScrollTo.current.scrollIntoView({ block: 'start', behavior: 'smooth'});
	}

	render(){
		const currentModelToRender = this.state.currentModel.length > 0;
		const isInInitialState = this.state.allModelData == null;

		return(
			<div>
				<div ref={this.jobsPageChangeScrollTo} />
				<div className="section-full bg-white ">
					
					<div className="container">
						{
							currentModelToRender &&
							<div className="row">&nbsp;</div>
						}
						{
							currentModelToRender &&
							<div className="row">
								<div className="col-xl-9 col-lg-9 col-sm-7 col-md-8 col-xs-8"></div>
								<div className="col-xl-3 col-lg-3 col-sm-5 col-md-4 col-xs-4 col-12">
									Sortuj:
									<Select options={this.state.allOrderByFields} closeMenuOnSelect={true}
										onChange={this.onOrderByFieldChange} 
										placeholder="Sortowanie po: " value={ this.state.currentOrderBy } />
								</div>
							</div>
						}
						{
							currentModelToRender &&
							<div className="row">&nbsp;</div>
						}
						{
							currentModelToRender && 
							<div className="row">
								<div className="col-lg-12 col-md-12 col-xs-12 m-b40">
									<ul className="post-job-bx browse-job">
										{
											this.state.currentModel.map((singleJobOffer, index) => (
												<li key={singleJobOffer.hashCode}>
													<SearchJobListItem {...singleJobOffer} />
												</li>
											))
										}	 								
									</ul>
									<div className="d-flex justify-content-center">
										<PaginationExtended items={this.state.allModelData!} onChangePage={this.onChangePage} initialPage={1} pageSize={this.props.MaxJobsPerPage} /> 
									</div>
								</div>
							</div>
						}
						{ 
							!isInInitialState && 
							!currentModelToRender &&
							<div className="row">
								<div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
									<div className="d-flex justify-content-center">
										<h3>Brak wyników</h3> { /* todo: fudged! */  }
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}
export default Jobsection;

