import React from 'react';
import './FetchLoadingIndicator.css';
import { resolve }  from 'inversify-react';
import * as config from '../../../src/config/';

export class FetchLoadingIndicator extends React.Component {

    @resolve(config.Types.GeneralApplicationSettings)
    private readonly _generalApplicationSettings!: config.IKeepGeneralApplicationData;

    state = {
        fetchInvocationsCount: 0
    }

    constructor(props: any) {
        super(props);
        const wnd = window as any;
        const tmpFetch = globalThis.fetch;
        if (wnd.fetchAlreadyMockedByLoadingIndicator == null) {
            wnd.fetchInvocationsCount = 0;
            globalThis.fetch = (async (url, options) => {
                if (!this._generalApplicationSettings.fetchLoadingIdicatorUrls.some((u) => url.toString().startsWith(u))) {
                    return tmpFetch(url, options);    
                }

                wnd.fetchInvocationsCount++;
                this.setState({ fetchInvocationsCount: wnd.fetchInvocationsCount });

                try
                {
                    const requestPromise = tmpFetch(url, options);
                    const response = await requestPromise;
                    return response;
                }
                catch (e)
                {
                    throw e;
                }
                finally
                {
                    wnd.fetchInvocationsCount--;
                    this.setState({ fetchInvocationsCount: wnd.fetchInvocationsCount });
                }
            }) as typeof globalThis.fetch;
            wnd.fetchAlreadyMockedByLoadingIndicator = true;
        }
    }

    render() {
        return (
                this.state.fetchInvocationsCount > 0 &&
                <div>
                    <div className="loading-indicator">
                        <div className="subline"></div>
                    </div>
                    <div className="fetch-loading-overlay" />
                </div>
        );
    }
}